// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  hmr: false,
  keys: {
    token: 'TOKEN_PROXY_KEY',
    refreshToken: 'REFRESH_TOKEN_PROXY_KEY',
    user: 'USER_PROXY_KEY',
  },
  config: {
    redirectToWhenAuthenticated: '/pages/dashboard',
    redirectToWhenUnauthenticated: '/auth/login',
  },
  api: {
    baseUrl: 'https://api.b2bbee.ligafacens.com/dev',
    auth: {
      login: '/auth/local',
    },
    address: {
      citiesRegistered: '/addresses/cities-registered',
    },
    users: {
      list: '/users',
      get: '/users/{userId}',
      create: '/users',
      update: '/users/{userId}',
      me: '/users/me',
    },
    banners: {
      list: '/banners',
      get: '/banners/{bannerId}',
      create: '/banners',
      update: '/banners/{bannerId}',
      delete: '/banners/{bannerId}',
    },
    categories: {
      list: '/categories',
      get: '/categories/{categoryId}',
      create: '/categories',
      update: '/categories/{categoryId}',
      delete: '/categories/{categoryId}',
    },
    companies: {
      list: '/companies',
      get: '/companies/{userId}',
      create: '/companies',
      update: '/companies/{userId}',
    },
    complaints: {
      list: '/complaints',
      get: '/complaints/{complaintId}',
    },
    dashboard: {
      get: '/dashboards/quantity-report',
    },
    faqGroup: {
      list: '/faqs-groups',
      get: '/faqs-groups/{faqGroupId}',
      create: '/faqs-groups',
      update: '/faqs-groups/{faqGroupId}',
      delete: '/faqs-groups/{faqGroupId}',
    },
    faqCategory: {
      list: '/faqs-categories',
      get: '/faqs-categories/{faqCategoryId}',
      create: '/faqs-categories',
      update: '/faqs-categories/{faqCategoryId}',
      delete: '/faqs-categories/{faqCategoryId}',
    },
    faqTopic: {
      list: '/faqs-topics',
      get: '/faqs-topics/{faqTopicId}',
      create: '/faqs-topics',
      update: '/faqs-topics/{faqTopicId}',
      delete: '/faqs-topics/{faqTopicId}',
    },
    keywords: {
      list: '/key-words',
      get: '/key-words/{keywordId}',
      create: '/key-words',
      update: '/key-words/{keywordId}',
      delete: '/key-words/{keywordId}',
    },
    plans: {
      list: '/plans',
      get: '/plans/{planId}',
      create: '/plans',
      update: '/plans/{planId}',
      delete: '/plans/{planId}',
      pagarme: '/plans/{pagarmeId}/pagarme',
    },
    products: {
      list: '/products',
      get: '/products/{productId}',
      create: '/products',
      update: '/products/{productId}',
      delete: '/products/{productId}',
    },
    ratings: {
      list: '/companies-ratings',
      get: '/companies-ratings/{ratingId}',
      create: '/companies-ratings',
      update: '/companies-ratings/{ratingId}',
      delete: '/companies-ratings/{ratingId}',
    },
    userPassword: {
      forgotPassword: '/users-password/forgot/email/{email}',
      resetPassword: '/users-password/reset/{resetPasswordCode}',
      newPassword: '/users-password/change',
    },
  },
};
